<template>
  <div class="hp-message-box">
    message
  </div>
</template>

<script>
export default {
  name: 'HpMessageBox'
}
</script>

<style lang="scss">
  @import '~@/assets/style/hpComponent/messageBox.scss';
</style>
